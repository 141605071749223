<template>
  <vx-card title="Edit Setting">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" readonly="true" :value="this.name" />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" readonly="true" :value="this.description" />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Platform</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" readonly="true" :value="this.platform" />
      </div>
    </div>
    <div
      class="vx-row mb-6"
      style="width: 50%"
      v-if="this.setting_type == 'integer'"
    >
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Value</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="value" />
      </div>
    </div>

    <div
      class="vx-row mb-6"
      style="width: 50%"
      v-if="this.setting_type == 'string'"
    >
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Value</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="value" />
      </div>
    </div>

    <div
      class="vx-row mb-6"
      style="width: 50%"
      v-if="this.setting_type == 'option'"
    >
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Option</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select :filterable="true" :options="options" v-model="value" />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleBack"
          >Back</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>
<script>
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/account/v1/setting",
        settingID: null,
        name: null,
        description: null,
        platform: "",
        value: null,
        setting_type: "",
        options: [],
      };
    },
    handleBack() {
      this.$router.push({
        name: "setting",
      });
    },
    handleSubmit() {
      var value = "";
      if (this.setting_type === "integer") {
        value = parseInt(this.value);
      } else if (this.setting_type === "option") {
        value = this.value.value;
      } else if (this.setting_type === "string") {
        value = this.value;
      }

      let body = {
        value: value,
        app: "sfa",
        platform: this.platform,
      };
      console.log(body);

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.$http
            .put(this.baseUrl + "/" + this.settingID + "/update", body)
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });

                this.$vs.loading.close();
                setTimeout(this.handleBack(), 2000);
              } else {
                this.$vs.loading.close();
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        }
      });
    },
    getSetting() {
      if (this.settingID) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/" + this.settingID + "/edit", {
            params: {
              platform: this.platform,
            },
          })
          .then((resp) => {
            this.name = resp.data.setting.name;
            this.description = resp.data.setting.description;
            this.setting_type = resp.data.setting.type;
            this.options = resp.data.setting.options;

            if (this.setting_type == "option") {
              var val = resp.data.setting_value.value
                ? resp.data.setting_value.value
                : resp.data.setting.default_value;
              console.log(val);

              let filterdValue = this.options.filter((a) => {
                console.log(a.value);
                return a.value == val;
              });

              this.value = filterdValue[0];
            } else {
              this.value = resp.data.setting_value.value
                ? resp.data.setting_value.value
                : resp.data.setting.default_value;
            }

            this.$vs.loading.close();
          });
      } else {
        this.optionPersonal = [];
      }
    },
  },

  mounted() {
    this.settingID = this.$route.params.id;
    this.platform = this.$route.params.platform;
    this.getSetting();
  },
  watch: {
    value(val) {
      console.log(val);
    },
  },
};
</script>
